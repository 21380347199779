import React, { useContext, useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../Context/Authentication";
import { getTaskById } from "../../Context/Queries";
import { UpdateTaskStep1 } from "./UpdateTaskStep1";
import { UpdateTaskStep2 } from "./UpdateTaskStep2";
import { UpdateTaskStep3 } from "./UpdateTaskStep3";
import { UpdateTaskStep4 } from "./UpdateTaskStep4";

import {
  Button,
  CircularProgress,
  Alert,
  FormControlLabel,
  FormGroup,
  Checkbox,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
} from "@mui/material";
import Axios from "axios";
export const UpdateTask = (props) => {
  const [task, setTask] = useState(null);
  const [step, setStep] = useState(0);
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const getTask = async () => {
    await Axios(`/api/v1/tasks/${props.match.params.id}`).then((res) => {
      setTask(res.data.data[0])
    });
  };
  useEffect(() => {
    getTask();
  }, []);
  const nextStep = () => {
    setStep(step + 1);
  };
  const prevStep = () => {
    setStep(step - 1);
  };
  const formSteps = () => {
    switch (step) {
      case 0:
        return <Button variant="contained" onClick={() => nextStep()}> أبدا التعديل</Button>
      case 1:
        return <UpdateTaskStep1 task={task} />;
      case 2:
        return <UpdateTaskStep2 task={task} />;
      case 3:
        return <UpdateTaskStep3 task={task} />;
      case 4:
        return <UpdateTaskStep4 task={task} />;
      case 5:
        return "Congratolations";
    }
  };
  return (
    <>
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-3xl font-bold text-gray-900">تعديل المهمة</h1>
        </div>
      </header>
      <div className="mx-auto" style={{ maxWidth: "600px" }}>
        <div className="steps-dot flex align-center">
          <span className="dot active">1</span>
          <span className={"line " + (step > 1 ? " active" : "")}></span>
          <span className={"dot " + (step > 1 ? " active" : "")}>2</span>
          <span className={"line " + (step > 2 ? " active" : "")}></span>
          <span className={"dot " + (step > 2 ? " active" : "")}>3</span>
          <span className={"line " + (step > 3 ? " active" : "")}></span>
          <span className={"dot " + (step > 3 ? " active" : "")}>4</span>
          {/* <span className={"line " + (step > 4 ? " active" : "")}></span>
          <span className={"dot " + (step > 4 ? " active" : "")}>5</span> */}
        </div>
        {formSteps()}
        <div className="steps-control mt-3 mb-5">
          {step > 1 && (
            <Button
              color="secondary"
              className="ml-3"
              onClick={() => prevStep()}
            >
              السابق
            </Button>
          )}
          {(step < 4 && step > 0 ) && (
            <Button color="secondary" onClick={() => nextStep()}>
              التالي
            </Button>
          )}
          {step == 4 && (
            <Button variant="contained">
              <Link to="/tasks">حفظ</Link>{" "}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
