import React, { useContext, useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AuthContext } from "../Context/Authentication";
import { Button, CircularProgress, Alert, Skeleton } from "@mui/material";
import Axios from "axios";
import "../Styles/Profile.css";
import { UpdateUserProfile } from "../Components/User/UpdateUserProfile";
import fileDownload from "js-file-download";
const Profile = () => {
  const { isAuth, userData, refresh, setRefresh } = useContext(AuthContext);
  const history = useHistory();
  const [open, setOpen] = useState(null);
  const [user, setUser] = useState(null);
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const displayUserPermission = (perType) => {
    let x = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-4"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    );
    let o = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-4"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        />
      </svg>
    );
    const translationItem = (item) => {
      switch (item) {
        case "add":
          return "أضافة";
          break;
        case "view":
          return "عرض";
          break;
        case "edit":
          return "تعديل";
          break;
        case "delete":
          return "حذف";
          break;
        default:
          return item;
          break;
      }
    };
    return Object.entries(perType).map((per) => {
      return (
        <span>
          <span>{translationItem(per[0])}</span>
          {per[1] ? o : x}
        </span>
      );
    });
  };
  const uploadAvatar = async (e) => {
    let bodyFormData = new FormData();
    bodyFormData.append("avatar", e.target.files[0]);
    // let reader = new FileReader();
    // reader.addEventListener('load',() => {
    //   let img = reader.result;
    //   document.querySelector('.avatar-img').src = img
    //   console.log(img)
    // })
    // reader.readAsDataURL(e.target.files[0])
    try {
      setIsLoading(true);
      await Axios({
        url: "/api/v1/users/avatar",
        method: "patch",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: bodyFormData,
      });
      setIsLoading(false);
      let isRefresh = !refresh;
      setRefresh(isRefresh);
    } catch (err) {
      console.log(err);
    }
  };
  const uploadAttach = async (e) => {
    let files = e.target.files;
    const bodyFormData = new FormData();
    for(let i=0; i < files.length; i++){
      bodyFormData.append("attach", files[i]);
    }
    try {
      await Axios({
        url: "/api/v1/users/attach",
        method: "patch",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: bodyFormData,
      });
      let isRefresh = !refresh;
      setRefresh(isRefresh);
    } catch (err) {
    }
    setTimeout(() => {
      setResponse(null);
    }, 3000);
  };
  const getUserProfile = async (userId) => {
    try {
      await Axios(`/api/v1/users/${userId}`).then((res) => {
        setUser(res.data.data);
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    userData && getUserProfile(userData.id);
    console.log(userData)
  }, []);
  if (!isAuth) {
    history.push("/");
  }
  const handelDownload = (url, filename) => {
    Axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };
  const displayUserAttach = (attach) => {
    if (attach) {
      let attachList = JSON.parse(attach);
      return attachList.map((attachFile, index) => {
        return (
          <Button
            size="small"
            className="ml-1 mb-1 "
            color="success"
            type="button"
            variant="contained"
            onClick={() => handelDownload(attachFile, `مرفق ${index}`)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg> 
            حمل المرفق
          </Button>
        );
      });
    }
  };

  const openPopupEdit = () => {
    setOpen(true);
  };
  return (
    <>
      <Helmet>
        <title>معلومات الحساب</title>
      </Helmet>

      {/* Edit Avatar */}
      <div className="avatar flex justify-center">
        <div className="avatar-container">
          <img
            className="avatar-img"
            src={
              (userData && userData.avatar) ||
              "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            }
          />
          <span className="icon-edit">تغيير الصورة</span>
          <form>
            <input
              type="file"
              className=""
              onChange={(e) => uploadAvatar(e)}
              placeholder="اضافة صورة "
            />
          </form>
          {isLoading && (
            <Skeleton
              variant="rectangular"
              style={{ width: "100%", height: "100%" }}
              width={100}
              height={100}
            />
          )}
        </div>
      </div>
      {/* Edit Avatar */}
      {/* Update User Profile */}
      {user && (
        <UpdateUserProfile
          user={user}
          open={open}
          setOpen={setOpen}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
      {/* Update User Profile */}
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-3xl font-bold text-gray-900">معلومات الحساب</h1>
        </div>
      </header>
      <div className="user-profile mt-5 px-5">
        <div className="user-profile-info">
          <div className="flex flex-wrap">
            <ul className="ml-5">
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
                <span>{userData && userData.username}</span>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                <span>{userData && userData.mobile}</span>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  />
                </svg>
                <span>{userData && userData.country}</span>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <span>
                  {user && user.job_title
                    ? user.job_title
                    : "لا يوجد مسمي وظيفي"}
                </span>
              </li>
              <Button variant="contained" onClick={openPopupEdit}>
                تعديل الحساب
              </Button>
            </ul>
            <div className="user-attach my-5">
              <div className="show-attach mb-2">
                {user && user.attach
                  ? displayUserAttach(user.attach)
                  : "لا يوجد مرفق!"}
              </div>
              <div className="upload-attach w-80">
                <label className=" flex flex-col items-center px-4 py-6 bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-purple-600 hover:text-white text-purple-600 ease-linear transition-all duration-150">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <span className="mt-2 text-base leading-normal">
                    {(user && user.attach) ? 'أضافة مرفق' : 'تعديل المرفق'}
                    
                  </span>
                  <input
                    type="file"
                    multiple
                    onChange={(e) => uploadAttach(e)}
                    className="hidden"
                  />
                </label>
                {response && (
                  <Alert severity={response.severity}>{response.val} </Alert>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="user-permissions-container my-5">
          <h3>الصلاحيات </h3>
          <div className="flex flex-wrap">
            <div className="user-permissions">
              <h4>صلاحيات المستخدم</h4>
              <div className="flex ">
                {userData &&
                  displayUserPermission(JSON.parse(userData["userPermission"]))}
              </div>
            </div>
            <div className="user-permissions">
              <h4>صلاحيات المهمات</h4>
              <div className="flex">
                {userData &&
                  displayUserPermission(JSON.parse(userData["taskPermission"]))}
              </div>
            </div>
            <div className="user-permissions">
              <h4>صلاحيات المشاوير</h4>
              <div className="flex">
                {userData &&
                  displayUserPermission(
                    JSON.parse(userData["visitPermission"])
                  )}
              </div>
            </div>
            <div className="user-permissions">
              <h4>صلاحيات الاعدادات</h4>
              <div className="flex">
                {userData &&
                  displayUserPermission(
                    JSON.parse(userData["settingPermission"])
                  )}
              </div>
            </div>
            <div className="user-permissions">
              <h4>صلاحيات الدفع</h4>
              <div className="flex">
                {userData &&
                  displayUserPermission(
                    JSON.parse(userData["paymentPermission"])
                  )}
              </div>
            </div>
            <div className="user-permissions">
              <h4>صلاحيات التعليقات</h4>
              <div className="flex">
                {userData &&
                  displayUserPermission(
                    JSON.parse(userData["commentPermission"])
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
