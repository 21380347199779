import Axios from "axios";

export const getTaskById = async (id) => {
  try {
    return await Axios(`/api/v1/tasks/${id}`).then(res => res.data)
  } catch (err) {
    return err;
  }
};
export const getUserById = async (id) => {
  try {
    return await Axios(`/api/v1/users/${id}`).then(res => res.data)
  } catch (err) {
    return err;
  }
};
