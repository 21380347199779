import React from 'react'
import { Menu } from './Menu'
import { UserBox } from '../User/UserBox'

export const Sidebar = () => {
  return (
    <>
      <UserBox />
      <Menu />
    </>
  )
}
