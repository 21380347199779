import React,{ useState, useEffect } from 'react'
import { getUserById } from "../../Context/Queries";
export const DisplayUserInVisit = ({ userId }) => {
  const [user, setUser] = useState({})
  const getUser = async () => {
  try {
    let userData = await getUserById(userId).then((res) => res.data);
    setUser(userData)
  } catch (error) {}}
  useEffect(() => {
    getUser()
  }, [])
  return (
    <>
      <div className="flex-shrink-0 h-10 w-10">
        <img
          className="h-10 w-10 rounded-full"
          src={
            user.avatar ||
            "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"
          }
          alt=""
        />
      </div>
      <div className="mr-4">
        <div className="text-sm font-medium text-gray-900">
          {user.username || "الاسم"}
        </div>
        <div className="text-sm text-gray-500">{user.mobile}</div>
      </div>
      </>
    )
}
