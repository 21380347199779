import Axios from "axios";
import React, { useState } from "react";
import { Button, CircularProgress, Alert } from "@mui/material";

export const AddTaskStep2 = ({ taskId }) => {
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const uploadAttach = async (e) => {
    console.log(taskId)
    console.log(e.target.files)
    let files = e.target.files;
    const bodyFormData = new FormData();
    for(let i=0; i < files.length; i++){
      bodyFormData.append("attach", files[i]);
    }
    try {
      setIsLoading(true)
      await Axios({
        url: `/api/v1/tasks/${taskId}/attach`,
        method: "patch",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: bodyFormData,
      });
      setResponse({
        severity: "success",
        val: "تم أضافة المرفق بنجاح",
      });
    } catch (err) {
      setResponse({
        severity: "error",
        val: "يوجد خطأ,يرجي المحاولة مرة اخري ",
      });
    }
    setIsLoading(false)
    setTimeout(() => {
      setResponse(null);
    }, 3000);
  };

  return (
    <>
      <h3 className="mb-3">أضافة مرفق</h3>
      <label className="flex flex-col items-center px-4 py-6 bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-purple-600 hover:text-white text-purple-600 ease-linear transition-all duration-150">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
          />
        </svg>
        <span className="mt-2 text-base leading-normal">أضافة مرفق</span>
        <input
          type="file"
          multiple
          onChange={(e) => uploadAttach(e)}
          className="hidden"
        />
      </label>
      {isLoading && <CircularProgress color="secondary" />} 
       
      {response && <Alert severity={response.severity}>{response.val} </Alert>}
    </>
  );
};
