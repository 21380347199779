import Axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-mui-multiselect-dropdown";
import {
  Button,
  CircularProgress,
  Skeleton,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  Alert,
} from "@mui/material";
export const AddTaskStep4 = ({ taskId }) => {
  const [users, setUsers] = useState(null);
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const addUserTask = async (e,userId) => {
    try {
      setIsLoading(true);
      await Axios.post(`/api/v1/user/${userId}/tasks/${taskId}`).then(res => {
        e.target.innerText = 'مضاف'
        e.target.setAttribute('disabled','disabled')
      })
      setIsLoading(false);
    } catch (err) {
      console.log(err)
     }
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        let { data: allUsers } = await Axios("/api/v1/users");
        await setUsers(allUsers.data);
      } catch (error) {
        console.log(error);
      }
    };
    getUsers();
  }, []);

  return (
    <>
      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                الاسم
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                الوظيفية
              </th>
              <th scope="col" className="relative px-6 py-3">
                <span className="sr-only"></span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {users &&
              users.map((user) => {
                return (
                  <tr key={user.id} id={user.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          {/* Aavatar */}
                          <img
                            className="h-10 w-10 rounded-full"
                            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"
                            alt=""
                          />
                        </div>
                        <div className="mr-4">
                          <div className="text-sm font-medium text-gray-900">
                            {user.username ? user.username : "بدون اسم"}
                          </div>
                          <div className="text-sm text-gray-500">
                            {user.mobile}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {user.jobTitle ? user.jobTitle : "لا يوجد مسمي وظيفي"}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <Button
                        disabled={isLoading}
                        size="small"
                        variant="contained"
                        type="button"
                        onClick={(e) => addUserTask(e,user.id)}
                      >
                        أضافة
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};
