import React, { useState, useEffect, useContext } from "react";
import { useHistory, Link, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as Yup from "yup";
import { AuthContext } from "../Context/Authentication";

import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  CircularProgress,
  Alert,
} from "@mui/material";
import Axios from "axios";
import handleError from "../utils/handleError";
const Login = () => {
  const history = useHistory();
  const { isAuth } = useContext(AuthContext);
  if (isAuth) {
    return <Redirect to="/" />
  }
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  const handelLogin = async (userData) => {
    try {
      setIsLoading(true);
      setError(null);
      await Axios.post("/api/v1/signin", userData);
      setIsLoading(false);
      history.push("/");
    } catch (err) {
      handleError(setError, err);
      setIsLoading(false);
    }
  };
  return (
    <>
      <Helmet>
        <title>تسجيل الدخول</title>
      </Helmet>
      <Formik
        initialValues={{
          mobile: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          mobile: Yup.string().min(8).max(255).required("mobile is required"),
          password: Yup.string()
            .min(8)
            .max(255)
            .required("Password is required"),
        })}
        onSubmit={handelLogin}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <div className="login-container">
            <form onSubmit={handleSubmit}>
              <h1 className="text-center text-lg mb-5 text-gray-800">
                تسجيل الدخول
              </h1>
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label for="mobile" className="sr-only">
                    الموبايل
                  </label>
                  <input
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="أدخل رقم الهاتف"
                    error={Boolean(touched.mobile && errors.mobile)}
                    helperText={touched.mobile && errors.mobile}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    name="mobile"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.mobile}
                    type="text"
                  />
                </div>
                <div>
                  <label for="password" className="sr-only">
                    كلمة المرور
                  </label>
                  <input
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    autocomplete="current-password"
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="كلمة المرور"
                  />
                </div>
              </div>

              <Button
                color="primary"
                disabled={isLoading}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                className="mt-5"
              >
                {isLoading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  "تسجيل الدخول"
                )}
              </Button>
              {error && <Alert severity="error">{error} </Alert>}
              <Typography color="textSecondary" variant="body1">
                ليس لدي حساب{" "}
                <Link to="/register" variant="h6" underline="hover">
                  أنشاء حساب
                </Link>
              </Typography>
            </form>
          </div>
        )}
      </Formik>
    </>
  );
};

export default Login;
