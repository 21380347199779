import React, { useLayoutEffect, Suspense, useContext } from "react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
// import { useRoutes, Switch, Route } from 'react-router-dom';
import AuthProvider from "../Context/Authentication";
import Layout from "../pages/Layout";

import theme from "../theme";
const App = () => {
  useLayoutEffect(() => {
    document.body.setAttribute("dir", "rtl");
  }, []);
  return (
    <div className="App">
      <Suspense fallback="loading">
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <AuthProvider>
              <Layout />
            </AuthProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Suspense>
    </div>
  );
};

export default App;
