import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../Context/Authentication";

export const UserBox = () => {
  const [src, setSrc] = useState("https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80")
  const { userData, isAuth } = useContext(AuthContext);
  useEffect(() => {
    
  },[userData])
  return (
    <div className="border-b-2 border-gray-100 py-3 text-center mb-1">
      <img
        className="h-10 w-10 rounded-full mx-auto"
        src={(userData && userData.avatar) || src}
        alt="user image"
      />
      <h4 className="text-gray-800">{userData && userData.username}</h4>
      <small  className="text-gray-400">{userData && userData.username}</small>
    </div>
  );
};
