import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import '../../Styles/tasks.css';
import { AuthContext } from "../../Context/Authentication";

export const UsersTask = ({ taskId, setUserCount }) => {
  const { userData, isAuth } = useContext(AuthContext);
  const [users, setUsers] = useState({});
  const getTaskUser = async (taskId) => {
    if (taskId) {
      try {
        await Axios(`/api/v1/task/${taskId}/users`).then((res) => {
          setUsers(res.data.data);
          let len = res.data.data.length
          setUserCount(len)

        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    getTaskUser(taskId);
  },[]);
  return (
    <div className="task-users mb-5">
      <h3 className="text-center">أعضاء الفريق</h3>
      <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6">
        {users.length &&
          users.map((user) => {
            return (
              <div className="task-user-card">
                <figure>
                  <img src={userData && userData.avatar} />
                </figure>
                <h4>{user.username}</h4>
                <h4>
                  <small>{user.job_title}</small>
                </h4>
                <span className={"text-user-status " + user.status }>
                  {user.status == 'onwork' ? 'في العمل ' : (user.status == 'done' ? 'أكتمل' : 'أنتظار' ) }
                  </span>
              </div>
            );
          })}
      </div>
    </div>
  );
};
