import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory, Redirect } from "react-router-dom";
import { Box, Container, Grid } from "@mui/material";
import { AuthContext } from "../Context/Authentication";
import { UserSidebar } from "../Components/Dashboard/UserSidebar";
import { Results } from "../Components/Dashboard/Results";
import { UsersTask } from "../Components/Dashboard/UsersTask";
import { Members } from "../Components/Dashboard/Members";
import { QuickAdd } from "../Components/Dashboard/QuickAdd";
import '../Styles/dashboard.css';
const Dashboard = () => {
  const { isAuth, userData } = useContext(AuthContext);
  const history = useHistory()
  useEffect(() => {
    if(! isAuth){
      return <Redirect to="/login" />
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>لوحة التحكم</title>
      </Helmet>
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-3xl font-bold text-gray-900">
            لوحة التحكم
          </h1>
        </div>
      </header>

      <div className="dashboard-head">
        <div className="results">
          <Results />
        </div>
        {/* <div className="dashboard-sidebar">
          <UserSidebar />
        </div> */}
      </div>
      <div className="dashboard-body">
        {/* users in tasks */}
        <div className="dashboard-line">
          <div className="members">
            <Members />
          </div>
          <div className="quick-add">
            <QuickAdd />
          </div>
        </div>
        <div className="dashboard-tables">
          <div className="task-table">
            <UsersTask />
          </div>
        </div>
      </div>

      {/* Quick add */}
      {/* users */}
      {/* task */}
      {/* comments */}
      {/* visits */}
      {/* task complete */}
      <div className="dashboard-footer"></div>
    </>
  );
};

export default Dashboard;
