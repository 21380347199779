import Axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory, Redirect } from "react-router-dom";
import fileDownload from "js-file-download";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import { UsersInTask } from "../Components/Tasks/UsersInTask";
import { AuthContext } from "../Context/Authentication";
export default function TasksList() {
  const { userData, isAuth } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [tasks, setTasks] = useState(null);
  const [continuo, setContinuo] = useState(false);
  const [elementId, setElementId] = useState(null);
  const [taskPer, setTaskPer] = useState([]);
  const history = useHistory();
  const userPermissions = () => {
    if (userData) {
      setTaskPer(JSON.parse(userData["taskPermission"]));
      if(taskPer && ! taskPer.view) return <Redirect to="/" />
    }
  };
  useEffect(() => {
    const getTasks = async () => {
      try {
        let { data: allTasks } = await Axios("/api/v1/tasks");
        await setTasks(allTasks.data);
      } catch (error) {}
    };
    getTasks();

    userPermissions();
  }, []);
  const handleClickOpen = (id) => {
    setOpen(true);
    setElementId(id);
  };
  //
  const handleClose = async (isContinuo) => {
    setOpen(false);
    setContinuo(isContinuo);
    deleteTask();
  };
  //
  const getTypeOfTask = (type) => {
    switch (type) {
      case "normal":
        return "عادي";
      case "settion":
        return "تسوية";
      case "other":
        return "أخري";
      default:
        return type;
    }
  };
  const deleteTask = async () => {
    if (continuo) {
      try {
        document.getElementById(elementId).classList +=
          "bg-red-200 text-center";
        await Axios.delete(`/api/v1/tasks/${elementId}`);
        document.getElementById(elementId).innerHTML = "تم الحذف بنجاح";
        setTimeout(() => {
          elementId && document.getElementById(elementId).remove();
        }, 1000);
        setElementId(null);
      } catch (err) {
        console.log(err);
      }
      setContinuo(false);
    } else setElementId(null);
  };
  const handelDownload = (url, filename) => {
    Axios.get(url, {
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, filename);
    });
  };
  const displayTaskFiles = (attach, type) => {
    if (attach) {
      let attachList = JSON.parse(attach);
      return attachList.map((attachFile, index) => {
        return (
          <Button
            size="small"
            className="ml-1 mb-1 "
            color="success"
            type="button"
            variant="contained"
            onClick={() => handelDownload(attachFile, `مرفق ${index}`)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
            حمل {type}
          </Button>
        );
      });
    }
  };
  // const displayTasks = () =>

  return (
    <>
      <Helmet>
        <title>المهمات</title>
      </Helmet>
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-3xl font-bold text-gray-900">المهمات</h1>
          {taskPer && taskPer.add && (
            <Link
              to="add-task"
              className="text-white text-sm bg-blue-500 rounded-md p-2 hover:bg-blue-300 flex align-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 4v16m8-8H4"
                />
              </svg>
              <span>أضافة مهمة</span>
            </Link>
          )}
        </div>
      </header>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            هل انت متاكد من الحذف ؟
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>الغاء</Button>
          <Button onClick={() => handleClose(true)} autoFocus>
            حذف
          </Button>
        </DialogActions>
      </Dialog>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      الفريق
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      نوع المهمة
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      الاسم
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      تاريخ البدء
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      تاريخ الانتهاء
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      بروتوكول
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      مرفقات
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only"></span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {tasks &&
                    tasks.map((task) => {
                      return (
                        <tr key={task.id} id={task.id}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center relative">
                              {task && (
                                <UsersInTask taskId={task.id} />
                              )}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {task.type && getTypeOfTask(task.type)}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {task.name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {new Date(task.date).toLocaleDateString()}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {new Date(task.deadline).toLocaleDateString()}
                          </td>
                          <td className="px-6 flex-column flex py-4 whitespace-nowrap text-sm text-gray-500">
                              {displayTaskFiles(task.protocol, "البروتوكول") ||
                                "لايوجد بروتوكول"}
                          </td>
                          <td className="flex-column flex px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {displayTaskFiles(task.attach, "المرفق") ||
                              "لايوجد مرفقات"}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            {taskPer && taskPer.edit && (
                              <Link
                                className="text-indigo-600 hover:text-indigo-900 ml-1"
                                to={`tasks/${task.id}`}
                              >
                                تعديل
                              </Link>
                            )}
                            {taskPer && taskPer.delete && (
                              <a
                                href="#"
                                className="text-red-600 hover:text-red-900 mr-1"
                                data-del={task.id}
                                onClick={() => handleClickOpen(task.id)}
                              >
                                حذف
                              </a>
                            )}
                            {/* Check If USER OUT OF MISSiON */}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
