import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { Button, CircularProgress, Alert } from "@mui/material";
import Axios from "axios";

const AddUser = () => {
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState();
  const [mobile, setMobile] = useState();
  const [country, setCountry] = useState();
  const [jobTitle, setJobTitle] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const addNewUser = async () => {
    if (username && mobile && password === passwordConfirmation) {
      try {
        setIsLoading(true);
        await Axios.post("/api/v1/users", {
          mobile,
          country,
          username,
          jobTitle,
          password,
          passwordConfirmation
        });
        setResponse({
          severity: "success",
          val: "تم اضافة مستخدم جديد بنجاح",
        });
        setIsLoading(false);
        setUsername("");
        setMobile(''),
        setCountry(''),
        setJobTitle(''),
        setPassword(''),
        setPasswordConfirmation('')
      } catch (err) {
        console.log(err)
        setResponse({
          severity: "error",
          val: "خطأ, يرجي المحاولة مرة اخري",
        });
      }
      setTimeout(() => {
        setIsLoading(false);
        setResponse("");
      }, 2000);
    }
  };
  return (
    <>
      <Helmet>
        <title>اضافة مستخدم</title>
      </Helmet>
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-3xl font-bold text-gray-900">
            أضافة مستخدم جديد
          </h1>
        </div>
      </header>
      <form
        action="#"
        method="POST"
        className="relative"
      >
        <div className="shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-4">
                <label
                  for="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  الاسم الكامل
                </label>
                <input
                  type="text"
                  value={username}
                  name="username"
                  placeholder="الاسم الكامل"
                  autocomplete="username"
                  className="mt-1 focus:ring-indigo-500 py-2 px-3 border focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-1 border-gray-300 rounded-md"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>

              <div className="col-span-6 sm:col-span-4">
                <label
                  for="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  رقم الهاتف
                </label>
                <input
                  type="text"
                  name="mobile"
                  value={mobile}
                  placeholder="رقم الهاتف"
                  autocomplete="mobile"
                  className="mt-1 py-2 px-3 border block w-full shadow-sm sm:text-sm border-1 border-gray-300 rounded-md"
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>
              <div className="col-span-6 sm:col-span-4">
                <label
                  for="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  البلد
                </label>
                <input
                  type="text"
                  name="country"
                  value={country}
                  placeholder="البلد"
                  autocomplete="country"
                  className="mt-1 py-2 px-3 border block w-full shadow-sm sm:text-sm border-1 border-gray-300 rounded-md"
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>
              <div className="col-span-6 sm:col-span-4">
                <label
                  for="jobTitle"
                  className="block text-sm font-medium text-gray-700"
                >
                  المسمي الوظيفي
                </label>
                <input
                  type="text"
                  name="jobTitle"
                  value={jobTitle}
                  placeholder="المسمي الوظيفي"
                  autocomplete="jobTitle"
                  className="mt-1 py-2 px-3 border block w-full shadow-sm sm:text-sm border-1 border-gray-300 rounded-md"
                  onChange={(e) => setJobTitle(e.target.value)}
                />
              </div>
              <div className="col-span-6 sm:col-span-4">
                <label
                  for="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  كلمة السر
                </label>
                <input
                  type="password"
                  name="password"
                  value={password}
                  placeholder="كلمة السر"
                  autocomplete="password"
                  className="mt-1 py-2 px-3 border block w-full shadow-sm sm:text-sm border-1 border-gray-300 rounded-md"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="col-span-6 sm:col-span-4">
                <label
                  for="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  تاكيد كلمة السر
                </label>
                <input
                  type="password"
                  name="passwordConfirmation"
                  value={passwordConfirmation}
                  placeholder=" تاكيد كلمة السر "
                  autocomplete="passwordConfirmation"
                  className="mt-1 py-2 px-3 border block w-full shadow-sm sm:text-sm border-1 border-gray-300 rounded-md"
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 col-span-6 sm:col-span-4 o">
            <Button
              color="primary"
              disabled={isLoading}
              size="small"
              type="button"
              variant="contained"
              className="mb-3"
              onClick={addNewUser}
            >
              {isLoading ? (
                <CircularProgress color="secondary" />
              ) : (
                "أضافة مستخدم"
              )}
            </Button>
            {response && (
              <Alert severity={response.severity}>{response.val} </Alert>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddUser;
