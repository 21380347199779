import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../Context/Authentication";
import { getTaskById } from "../../Context/Queries";
import fileDownload from "js-file-download";

import {
  Button,
  CircularProgress,
  Alert,
  FormControlLabel,
  FormGroup,
  Checkbox,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
} from "@mui/material";
import Axios from "axios";
export const UpdateTaskStep2 = ({ task }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const uploadAttach = async (e) => {
    let files = e.target.files;
    const bodyFormData = new FormData();
    for(let i=0; i < files.length; i++){
      bodyFormData.append("attach", files[i]);
    }
    setIsLoading(true)
    await Axios({
        url: `/api/v1/tasks/${task.id}/attach`,
        method: "patch",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: bodyFormData,
      }).then(res => {
        setResponse({
          severity: "success",
          val: "تم تعديل الملف بنجاح",
        });
      }).catch(err => {
        setResponse({
          severity: "error",
          val: "خطأ, يرجي المحاولة مرة اخري",
        });
      })
      setIsLoading(false)
    setTimeout(() => {
      setResponse(null);
    }, 3000);
  };
  const handelDownload = (url, filename) => {
    Axios.get(url, {
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, filename);
    });
  };
  const displayTaskFiles = (attach, type) => {
    if (attach) {
      let attachList = JSON.parse(attach);
      return attachList.map((attachFile, index) => {
        return (
          <Button
            size="small"
            className="ml-1 mb-1 "
            color="success"
            type="button"
            variant="contained"
            onClick={() => handelDownload(attachFile, `مرفق ${index}`)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
            حمل {type}
          </Button>
        );
      });
    }
  };
  return (
    <>
      <div className="task-files">
        {task.attach && displayTaskFiles(task.attach, "البروتوكول")}
      </div>
      <h3 className="mb-3">تعديل المرفق</h3>
      <label className="flex flex-col items-center px-4 py-6 bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-purple-600 hover:text-white text-purple-600 ease-linear transition-all duration-150">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
          />
        </svg>
        <span className="mt-2 text-base leading-normal">أضافة مرفق</span>
        <input
          type="file"
          multiple
          onChange={(e) => uploadAttach(e)}
          className="hidden"
        />
      </label>
      {isLoading && <CircularProgress color="secondary" />}
      {response && <Alert severity={response.severity}>{response.val} </Alert>}
    </>
  );
};
