import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AuthContext } from "../Context/Authentication";
import {
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import '../Styles/tasks.css';
import { CommentsList } from '../Components/Comments/CommentsList';
import { AddComment } from '../Components/Comments/AddComment';
import { UsersTask } from '../Components/TaskPage/UsersTask';
import { FilesTask } from '../Components/TaskPage/FilesTask';
export default function Task(props) {
  const { userData, isAuth } = useContext(AuthContext);
  const [refresh, setRefresh] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const [task, setTask] = useState({});
  const [taskPer, setTaskPer] = useState([]);
  const [commentPer, setCommentPer] = useState([]);
  
  const getTaskById = async (taskId) => {
    try {
      await Axios(`/api/v1/tasks/${taskId}`).then((res) => {
        setTask(res.data.data[0]);
        console.log(res.data.data[0]);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTaskById(props.match.params.id);
    const userPermissions = () => {
      if (userData) {
        setTaskPer(JSON.parse(userData["taskPermission"]));
        setCommentPer(JSON.parse(userData["commentPermission"]));
      }
      console.log(commentPer)
      console.log(taskPer)
    };
    userPermissions();
  }, [refresh]);

  const getTypeOfTask = (type) => {
    switch (type) {
      case "normal":
        return "عادي";
      case "settion":
        return "تسوية";
      case "other":
        return "أخري";
      default:
        return type;
    }
  };
    
  return (
    <>
      <Helmet>
        <title>{task && task.name}</title>
      </Helmet>
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-3xl font-bold text-gray-900">
            {task && task.name}
          </h1>
          {taskPer && taskPer.edit && (
            <Link
              to={`tasks/${task.id}`}
              className="text-white  mr-1 text-sm bg-indigo-600 rounded-md p-2 hover:bg-indigo-800 flex align-center"
            >
              <span>تعديل المهمة</span>
            </Link>
          )}
        </div>
      </header>
      <div className="task-single">
        {/* info */}
        <ul className="task-info mb-5">
          <li>
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
            </span>
            <strong>الاسم:</strong>
            <span>{task && task.name}</span>
          </li>
          <li>
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z"
                />
              </svg>
            </span>
            <strong>الحالة:</strong>
            <span>{task && getTypeOfTask(task.type)}</span>
          </li>
          <li>
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
            </span>
            <strong>تاريخ البدء:</strong>
            <span>{task && new Date(task.date).toLocaleDateString()}</span>
          </li>
          <li>
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
            </span>

            <strong>تاريخ الانتهاء:</strong>
            <span>{task && new Date(task.deadline).toLocaleDateString()}</span>
          </li>
          <li>
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </svg>
            </span>
            <strong>الرؤية:</strong>
            <span>{task.visibility ? "الجميع" : "خاص"}</span>
          </li>
          <li>
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>{" "}
            </span>
            <strong>عدد الاعضاء:</strong>
            <span>{userCount}</span>
          </li>
        </ul>
        {/* files */}
        <FilesTask task={task} />
        {/* users */}
        {task.id && <UsersTask taskId={task.id} setUserCount={setUserCount} />}
        <hr />
        {/* add comments */}
        { (commentPer.add && task) && <AddComment taskId={task.id} setRefresh={setRefresh}  /> }
        <hr className="mb-3" />
        {/* comments */}
        { (commentPer.view && task.id) && <CommentsList taskId={task.id} commentPer={commentPer} />}
        
      </div>
    </>
  );
}
