import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../Context/Authentication";
import {
  Button,
  CircularProgress,
  Alert,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Axios from "axios";
export const UpdatePaymentPopup = ({ open, setOpen, payment, setPayment, getPayments }) => {
  const [userId, setUserId] = useState(null);
  const [amount, setAmount] = useState("");
  const [month, setMonth] = useState("");
  const [months, setMonths] = useState("");
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const getMonths = () => {
    let monthList = [];
    monthList[0] = "يناير";
    monthList[1] = "فبراير";
    monthList[2] = "مارس";
    monthList[3] = "أبريل";
    monthList[4] = "مايو";
    monthList[5] = "يونيو";
    monthList[6] = "يوليو";
    monthList[7] = "أغسطس";
    monthList[8] = "سبتمبر";
    monthList[9] = "أكتوبر";
    monthList[10] = "نوفمبر";
    monthList[11] = "ديسمبر";
    setMonths(monthList);
    console.log(months);
  };
  const handleClose = () => {
    setOpen(false);
    setPayment(null);
  };
  useEffect(() => {
    setAmount(payment.amount);
    setMonth(payment.month);
    getMonths();
  }, []);

  const updatePayment = async (e) => {
    try {
      setIsLoading(true)
      await Axios.patch(`/api/v1//payments/${payment.id}/`,{
        amount,
        month
      });
      setIsLoading(false)
      setResponse({
        severity: "success",
        val: "تم التعديل بنجاح",
      });
      getPayments()
    } catch (err) {
      setResponse({
        severity: "error",
        val: "يوجد خطأ,يرجي المحاولة مرة اخري ",
      });
    }
    setTimeout(() => {
      setResponse(null);
    }, 3000);
  };
  const uploadAttachments = async (e) => {
    let files = e.target.files;
    const bodyFormData = new FormData();
    for(let i=0; i < files.length; i++){
      bodyFormData.append("attachments", files[i]);
    }
    try {
      await Axios({
        url: `/api/v1/payments/${payment.id}/attachments`,
        method: "patch",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: bodyFormData,
        
      });
      getPayments()
    } catch (err) {
      console.log(err)
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">تعديل بيانات الدفعة</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form action="#" method="POST" className="relative" style={{ minWidth:'300px' }}> 
              <div className="mb-3">
                <label
                  for="amount"
                  className="block text-sm font-medium text-gray-700"
                >
                  المبلغ
                </label>
                <input
                  type="number"
                  name="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="المبلغ"
                  autocomplete="amount"
                  className="mt-1 focus:ring-indigo-500 py-2 px-3 border focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-1 border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-3">
                <label
                  for="month"
                  className="block text-sm font-medium text-gray-700"
                >
                  الشهر
                </label>

                <select
                  type="month"
                  name="month"
                  onClick={(e) => setMonth(e.target.value)}
                  defaultValue={month}
                  autocomplete="month"
                  className="mt-1 focus:ring-indigo-500 py-2 px-3 border  focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                >
                  {months.length &&
                    months.map((month) => {
                      return <option value={payment.month}>{month}</option>;
                    })}
                </select>
              </div>
              <div className="mb-3">
                <label className=" flex flex-col items-center px-4 py-6 bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-purple-600 hover:text-white text-purple-600 ease-linear transition-all duration-150">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <span className="mt-2 text-base leading-normal">
                    أضافة مرفق
                  </span>
                  <input type="file" multiple className="hidden" onChange={e => uploadAttachments(e)}  />
                </label>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <Button
                  color="primary"
                  disabled={isLoading}
                  size="small"
                  type="button"
                  variant="contained"
                  className="mb-3"
                  onClick={updatePayment}
                >
                  {isLoading ? <CircularProgress color="secondary" /> : "حفظ"}
                </Button>
                {response && (
                  <Alert severity={response.severity}>{response.val} </Alert>
                )}
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>أغلاق</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
