import React, { useState, useContext, useEffect } from "react";
import Axios from 'axios';
import { Helmet } from "react-helmet";
import { Link, useHistory, Redirect } from "react-router-dom";
import { AuthContext } from "../Context/Authentication";
import { DisplayUserInVisit } from "../Components/Visits/DisplayUserInVisit";
import { UpdatePaymentPopup } from '../Components/Payments/UpdatePaymentPopup';
import {  Button } from "@mui/material";
import fileDownload from "js-file-download";

export default function Payments() {
  const { userData, isAuth } = useContext(AuthContext);
  const [payments, setPayments] = useState(null);
  const [paymentPer, setPaymentPer] = useState([]);
  const [payment, setPayment] = useState(null);
  const [open, setOpen] = useState(false);

  const history = useHistory();
  const userPermissions = () => {
    if (userData) {
      setPaymentPer(JSON.parse(userData["paymentPermission"]));
      if(paymentPer && ! paymentPer.view) return <Redirect to="/" />
    }
  };
  const getPayments = async () => {
    try {
      await Axios("/api/v1/payments").then((res) => {
        setPayments(res.data.data);
      });
    } catch (error) {
    }
  };
  useEffect(() => {
    getPayments();
    userPermissions();
  }, []);
  const handelDownload = (url, filename) => {
    Axios.get(url, {
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, filename);
    });
  };
  const displayTaskFiles = (attach) => {
    if (attach) {
      let attachList = JSON.parse(attach);
      return attachList.map((attachFile, index) => {
        return (
          <Button
            size="small"
            className="ml-1 mb-1 "
            color="success"
            type="button"
            variant="contained"
            onClick={() => handelDownload(attachFile, `مرفق ${index}`)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
            حمل المرفق
          </Button>
        );
      });
    }
  };

  const getMonth = (isMonth) => {
    let month = [];
    month[0] = "يناير";
    month[1] = "فبراير";
    month[2] = "مارس";
    month[3] = "أبريل";
    month[4] = "مايو";
    month[5] = "يونيو";
    month[6] = "يوليو";
    month[7] = "أغسطس";
    month[8] = "سبتمبر";
    month[9] = "أكتوبر";
    month[10] = "نوفمبر";
    month[11] = "ديسمبر";
    return month[isMonth];
  };

  const openPopupEdit = (payment) => {
    setPayment(payment);
    setOpen(true);
  };
  const deletePayment = async (paymentId) => {
    try {
      document.getElementById(paymentId).classList.add('bg-red-300')
      await Axios.delete(`/api/v1/payments/${paymentId}`).then (res => {
        document.getElementById(paymentId).remove()
      })
    } catch(err) {}
  }

  return (
    <>
     <Helmet>
        <title>لوحة التحكم</title>
      </Helmet>
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-3xl font-bold text-gray-900">المدفوعات</h1>
          {paymentPer && paymentPer.add && (
            <Link
              to="/add-payment"
              className="text-white text-sm bg-blue-500 rounded-md p-2 hover:bg-blue-300 flex align-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 4v16m8-8H4"
                />
              </svg>
              <span>دفع جديد</span>
            </Link>
          )}
        </div>
      </header>
      {/* update payment */}
      {payment && (
        <UpdatePaymentPopup
          getPayments={getPayments}
          payment={payment}
          setPayment={setPayment}
          setOpen={setOpen}
          open={open}
        />
      )}
      {/* update payment */}
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      الاسم
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      البلغ
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      المرفقات
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      الشهر
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only"></span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {payments &&
                    payments.map((payment) => {
                      return (
                        <tr key={payment.id } id={payment.id}>
                          <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">

                            {payment.user_id && (
                              <DisplayUserInVisit userId={payment.user_id} />
                            )}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                {payment.amount}
                              </span>
                            </div>
                            {/* <div className="text-sm text-gray-500">Optimization</div> */}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex-column flex">
                            {displayTaskFiles(payment.attachments) || "لايوجد مرفقات"}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {getMonth(payment.month)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          {( paymentPer && paymentPer.edit ) && (
                            <a
                              href="#"
                              onClick={(e) => openPopupEdit(payment)}
                              className="text-indigo-600 hover:text-indigo-900 ml-1"
                            >
                              تعديل
                            </a>
                          )} 
                          {( paymentPer && paymentPer.delete ) && (
                           
                            <a
                              href="#"
                              className="text-red-600 hover:text-red-900 mr-1"
                              onClick={() => deletePayment(payment.id)}
                            >
                              حذف
                            </a>
                          )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
