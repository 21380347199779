import React, { useContext } from "react";
import { AuthContext } from "../Context/Authentication";
import '../Styles/layout.css';
import { Sidebar } from "../Components/Layout/Sidebar";
import { Navbar } from "../Components/Layout/Navbar";
import AppRoutes from "../App/Routes/AppRoutes";
export default function Layout() {
  const  { isAuth } = useContext(AuthContext)
  return (
    <>
      <Navbar style={{ display:(!isAuth && 'none') }}/>
      <div className="layout">
        <aside className="sidebar bg-white shadow" style={{ display:(!isAuth && 'none') }} >
          <Sidebar />
        </aside>
        <div className="content full-content">
          <AppRoutes />
        </div>
      </div>
    </>
  );
}
