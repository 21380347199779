import React from 'react'
import { Link } from 'react-router-dom';
export const QuickAdd = () => {
  return (
    <>
      <Link to="/add-task">أضافة مهمة</Link>
      <Link to="/add-visit">أضافة مشوار</Link>
    </>
  )
}
