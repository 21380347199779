import React, { useState, useEffect } from "react";
import { UsersInTask } from "../Tasks/UsersInTask";
import Axios from "axios";
export const UsersTask = () => {
  const [tasks, setTasks] = useState(null)
  
  const limit = (list,limit) => {
    if(list.length > limit) {
      let listLimit = []
      for(let i = 0; i < limit ; i++){
        listLimit.push(list[i])
      }
      return listLimit
    } else return list

  }
  const getTasks = async () => {
    try {
      let { data: tasksData } = await Axios("/api/v1/tasks");
      await setTasks(limit(tasksData.data, 5));
    } catch (error) {
    }
  };
  useEffect(() => {
    getTasks();
  },[])
  const getTypeOfTask = (type) => {
    switch (type) {
      case "normal":
        return "عادي";
      case "settion":
        return "تسوية";
      case "other":
        return "أخري";
      default:
        return type;
    }
  };
  return (
    <div className="flex flex-col">
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  الفريق
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  نوع المهمة
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  الاسم
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  تاريخ البدء
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  تاريخ الانتهاء
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {tasks &&
                tasks.map((task) => {
                  return (
                    <tr key={task.id} id={task.id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center relative">
                          {task && (
                            <UsersInTask taskId={task.id} />
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {task.type && getTypeOfTask(task.type)}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {task.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {new Date(task.date).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {new Date(task.deadline).toLocaleDateString()}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);
};
