import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import '../../Styles/tasks.css';
import {  Button } from "@mui/material";
import fileDownload from "js-file-download";

export const FilesTask = ({ task }) => {

  const handelDownload = (url, filename) => {
    Axios.get(url, {
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, filename);
    });
  };
  const displayTaskFiles = (attach, type) => {
    if (attach) {
      let attachList = JSON.parse(attach);
      return attachList.map((attachFile, index) => {
        return (
          <Button
            size="small"
            className="ml-1 mb-1 "
            color="success"
            type="button"
            variant="contained"
            onClick={() => handelDownload(attachFile, `مرفق ${index}`)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
            حمل {type}
          </Button>
        );
      });
    }
  };
  

  return (
    <div className="task-files mb-5">
    <div className="task-files-list">
      <h3><span>الملفات</span> </h3>
      <div className="flex">
        {displayTaskFiles(task.protocol, "ملفات") || "لايوجد ملفات"}
      </div>
    </div>
    <div className="task-files-list">
      <h3><span>البروتوكول</span></h3>
      <div className="flex">
        {displayTaskFiles(task.protocol, "البروتوكول") ||
          "لايوجد بروتوكول"}
      </div>
    </div>
  </div>
  )
}
