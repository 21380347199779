import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import {
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import '../../Styles/tasks.css';

import { UserComment } from './UserComment';
export const CommentsList = ({ taskId, commentPer }) => {
  const [comments, setComments] = useState([]);
  const getComments = async (taskId) =>{
    try {
      await Axios(`/api/v1/task/${taskId}/comments`).then(res => {
        setComments(res.data.data)
        console.log(res.data.data)
      })
    } catch(err){
      console.log(err)
    }
  } 
  useEffect(() => {
    getComments(taskId)
  },[])
  return (
  <div className="task-comments mb-5">
    {comments && comments.map(comment => {
      return (
        <UserComment comment={comment} key={comment.id} getComments={getComments} commentPer={commentPer}  />
      )
    })}
  </div>
  
  )
}
