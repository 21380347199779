import Axios from "axios";
import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";

export const AddTaskStep1 = ({ setTaskId }) => {
  const [name, setName] = useState("");
  const [deadline, setDeadline] = useState("normal");
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const addNewTask = async () => {
    if (name && deadline) {
      try {
        setIsLoading(true);
        await Axios.post(
          "/api/v1/tasks/",
          {
            name,
            deadline
          }).then(res => {
            console.log(res.data.taskId)
            setTaskId(res.data.taskId)
          })

        setResponse({
          severity: "success",
          val: "تم اضافة التاسك بنجاح",
        });
        setIsLoading(false);
      } catch (err) {
        setResponse({
          severity: "error",
          val: "خطأ, يرجي المحاولة مرة اخري",
        });
      }
      setTimeout(() => {
        setResponse('')
      },2000)
    }
  };

  return (
    <form
      action="#"
      method="POST"
      className="relative"
      enctype="multipart/form-data"
    >
      <div className="shadow overflow-hidden sm:rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-4">
              <label
                for="name"
                className="block text-sm font-medium text-gray-700"
              >
                الاسم
              </label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="الاسم"
                autocomplete="name"
                className="mt-1 focus:ring-indigo-500 py-2 px-3 border focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-1 border-gray-300 rounded-md"
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="col-span-6 sm:col-span-4">
              <label
                for="deadline"
                className="block text-sm font-medium text-gray-700"
              >
                تاريخ الانتهاء
              </label>
              <input
                type="date"
                name="deadline"
                id="deadline"
                placeholder="تاريخ الانتهاء"
                value={deadline}
                autocomplete="deadline"
                className="mt-1 py-2 px-3 border block w-full shadow-sm sm:text-sm border-1 border-gray-300 rounded-md"
                onChange={(e) => setDeadline(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 col-span-6 sm:col-span-4 o">
          <Button
            color="primary"
            disabled={isLoading}
            size="small"
            type="button"
            variant="contained"
            className="mb-3"
            onClick={addNewTask}
          >
            {isLoading ? <CircularProgress color="secondary" /> : "حفظ"}
          </Button>
          {response && (
            <Alert severity={response.severity}>{response.val} </Alert>
          )}
        </div>
      </div>
    </form>
  );
};
