import Axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory, Redirect } from "react-router-dom";
import { UpdateVisitPopup } from "../Components/Visits/UpdateVisitPopup";
import { DisplayUserInVisit } from "../Components/Visits/DisplayUserInVisit";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import { AuthContext } from "../Context/Authentication";
export default function Visits() {
  const { userData, isAuth } = useContext(AuthContext);
  const [visitPer, setVisitPer] = useState([]);
  const [open, setOpen] = useState(false);
  const [visits, setVisits] = useState(null);
  const [visit, setVisit] = useState(null);
  const [continuo, setContinuo] = useState(false);
  const [elementId, setElementId] = useState(null);
  const history = useHistory();
  const userPermissions = () => {
    if (userData) {
      setVisitPer(JSON.parse(userData["visitPermission"]));
      if(visitPer && ! visitPer.view) return <Redirect to="/" />
    }
  };

  const getVisits = async () => {
    try {
      let { data: allVisits } = await Axios("/api/v1/visits");
      await setVisits(allVisits.data);
    } catch (error) {}
  };
  useEffect(() => {
    getVisits();
    userPermissions();
  }, []);
  const deleteVisit = async (visitId) => {
    try {
    document.getElementById(visitId).classList +="bg-red-200 ";
    await Axios.delete(`/api/v1/visits/${visitId}`);
    visitId && document.getElementById(visitId).remove();
    } catch(err){

    }
  }
  const openPopupEdit = (visit) => {
    setVisit(visit);
    setOpen(true);
  };
  return (
    <>
      <Helmet>
        <title>المشاوير</title>
      </Helmet>
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-3xl font-bold text-gray-900">المشاوير</h1>
          {visitPer && visitPer.add && (
            <Link
              to="/add-visit"
              className="text-white text-sm bg-blue-500 rounded-md p-2 hover:bg-blue-300 flex align-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 4v16m8-8H4"
                />
              </svg>
              <span>أضافة مشوار</span>
            </Link>
          )}
        </div>
      </header>
      {/* Update user popup */}
      {visit && (
        <UpdateVisitPopup
          visit={visit}
          setVisit={setVisit}
          setOpen={setOpen}
          open={open}
          getVisits={getVisits}
        />
      )}
      {/* Update user popup */}

      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      الاسم
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      التاريخ
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      من بلد
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      الي بلد
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      المكان
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      حالة المشوار
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      عدد الكيلومتر
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only"></span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {visits &&
                    visits.map((visit) => {
                      return (
                        <tr key={visit.id} id={visit.id}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center" id={`displayUser_${visit.user_id}`}>
                              {visit.user_id && <DisplayUserInVisit userId={visit.user_id} /> }
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {new Date(visit.to_country).toLocaleDateString()}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {visit.from_country}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {visit.to_country}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {visit.location}
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                              {visit.status == "done"
                                ? "أكتمل"
                                : visit.status == "canceled"
                                ? "ملغية"
                                : "أنتظار"}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {visit.km_number}
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            {visitPer && visitPer.edit && (
                              <a
                                onClick={(e) => openPopupEdit(visit)}
                                className="text-indigo-600 hover:text-indigo-900 ml-1"
                              >
                                تعديل
                              </a>
                            )}
                            {visitPer && visitPer.delete && (
                              <a
                                href="#"
                                className="text-red-600 hover:text-red-900 mr-1"
                                onClick={() => deleteVisit(visit.id)}
                              >
                                حذف
                              </a>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
