import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { AuthContext } from "../../Context/Authentication";
import { Button, CircularProgress, Alert } from "@mui/material";
import "../../Styles/tasks.css";

export const UpdateComment = ({ commentContent, getComments }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [response, setResponse] = useState(null);
  const [comment, setComment] = useState("");
  const { userData, isAuth } = useContext(AuthContext);
  useEffect(() => {
    setComment(commentContent.comment)
  },[])
  const updateComment = async () => {
    if (comment) {
      try {
        setIsLoading(true);
        await Axios.patch(`/api/v1/task/comments/${commentContent.id}`, {
          comment,
        });
        setIsLoading(false);
        getComments(commentContent.task_id)
        document.getElementById(`boxComment${commentContent.id}`).classList.remove('editing')
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <div className="add-comment">
      <h3>تعديل تعليق</h3>
      <div className="flex">
        <img
          src={userData && userData.avatar}
          alt={userData && userData.username}
          className="ml-"
        />
        <textarea
          value={comment}
          placeholder="أكتب تعليق"
          onChange={(e) => setComment(e.target.value)}
          className="mt-1 focus:ring-indigo-500 py-2 px-3 border  focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <Button
        color="primary"
        disabled={isLoading}
        size="small"
        type="button"
        variant="contained"
        className="mt-3"
        onClick={updateComment}
      >
        {isLoading ? <CircularProgress color="secondary" /> : "حفظ"}
      </Button>
      {response && <Alert severity={response.severity}>{response.val} </Alert>}
    </div>
  );
};
