import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../Context/Authentication";

export const Menu = () => {
  const { userData, isAuth, setRefresh, refresh } = useContext(AuthContext);
  const [userPer, setUserPer] = useState([]);
  const [taskPer, setTaskPer] = useState([]);
  const [visitPer, setVisitPer] = useState([]);
  const [settingPer, setSettingPer] = useState([]);
  const [paymentPer, setPaymentPer] = useState([]);
  const sidebarCollapseToggle = () => {
    let sidebar = document.querySelector(".sidebar");
    sidebar.classList.toggle("collapse");
    document.querySelector(".content").classList.toggle("full-content");
  };
  const userPermissions = () => {
    if (userData) {
      setUserPer(JSON.parse(userData["userPermission"]));
      setTaskPer(JSON.parse(userData["taskPermission"]));
      setVisitPer(JSON.parse(userData["visitPermission"]));
      setSettingPer(JSON.parse(userData["settingPermission"]));
      setPaymentPer(JSON.parse(userData["paymentPermission"]));
    }
  };
  useEffect(() => {
    userPermissions();
  }, [userData]);
  // setRefresh(true);

  return (
    <ul className="sidebar-menu text-gray-600">
      <li className="sidebar-menu-item">
        <NavLink exact to="/" className="" tag="a">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
            />
          </svg>
          <span>لوحة التحكم</span>
        </NavLink>
      </li>
      <li className="sidebar-menu-item">
        <NavLink to="/profile" className="" tag="a">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            />
          </svg>
          <span>الملف الشخصي</span>
        </NavLink>
      </li>
      {taskPer && taskPer.view && (
        <li className="sidebar-menu-item">
          <NavLink to="/tasks" className="" tag="a">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
            <span> المهمات</span>
          </NavLink>
        </li>
      )}
      {visitPer && visitPer.view && (
        <li className="sidebar-menu-item">
          <NavLink to="/visits" className="" tag="a">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
              />
            </svg>
            <span>المشاوير</span>
          </NavLink>
        </li>
      )}
      { ( userPer && userPer.view ) && (

      <li className="sidebar-menu-item">
        <NavLink to="/users" className="" tag="a">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
          <span>المستخدمين</span>
        </NavLink>
      </li>
      )}
      { ( paymentPer && paymentPer.view ) && (
      <li className="sidebar-menu-item">
        <NavLink to="/payments" className="" tag="a">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>المدفوعات</span>
        </NavLink>
      </li>
      )}
      { ( settingPer && settingPer.view ) && (
    <li className="sidebar-menu-item">
        <NavLink to="/settings" className="" tag="a">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
          <span>الاعدادات</span>
        </NavLink>
      </li>
      )}
      <li className="sidebar-menu-item">
        <a className="collapse-sidebar" onClick={() => sidebarCollapseToggle()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h8m-8 6h16"
            />
          </svg>
          <span>تصغير القائمة</span>
        </a>
      </li>
  </ul>
  );
};

<svg
  xmlns="http://www.w3.org/2000/svg"
  className="h-6 w-6"
  fill="none"
  viewBox="0 0 24 24"
  stroke="currentColor"
>
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
  />
</svg>;
