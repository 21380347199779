import Axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { AuthContext } from '../Context/Authentication'
import { Link, Redirect } from "react-router-dom";

import {
  Button,
  CircularProgress,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
export default function Settings() {
  const { userData, isAuth } = useContext(AuthContext);
  const [settingPer, setSettingPer] = useState([]);
  const [settings, setSettings] = useState(null);
  const [kmCost, setKmCost] = useState("");
  const [response, setResponse] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userPermissions = () => {
    if (userData) {
      setSettingPer(JSON.parse(userData["settingPermission"]));
      if(settingPer && ! settingPer.edit) return <Redirect to="/" />
    }
  };
  useEffect(() => {
    console.log(userData)
    const getSettings = async () => {
      try {
        let { data: settings } = await Axios(`/api/v1/users/${userData && userData.id}/settings`);
        await setSettings(settings.data);
      } catch (error) {}
    };
    getSettings();
    userPermissions()
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };
  //
  const handleClose = () => {
    setOpen(false);
  };
 
  const editSetting = async () => {
    if (kmCost) {
      try {
        setIsLoading(true);
        await Axios.patch("/api/v1/users/settings", {
          kmCost,
        });
        setResponse({
          severity: "success",
          val: "تم تعديل الاعدادات بنجاح",
        });
        setIsLoading(false);
        getSettings()
        setKmCost("");
      } catch (err) {
        console.log(err);
        setResponse({
          severity: "error",
          val: "خطأ, يرجي المحاولة مرة اخري",
        });
      }
      setTimeout(() => {
        setIsLoading(false);
        setResponse("");
      }, 2000);
    }
  };
  return (
    <>
      <Helmet>
        <title> الاعدادات</title>
      </Helmet>
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-3xl font-bold text-gray-900">الاعدادات</h1>
          <Link
            to="#"
            onClick={() => setOpen(true)}
            className="text-white text-sm bg-blue-500 rounded-md p-2 hover:bg-blue-300 flex align-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4v16m8-8H4"
              />
            </svg>
            <span>تعديل الاعدادات</span>
          </Link>
        </div>
      </header>
      {/* Add setting */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">تعديل الاعدادات</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form className="form-update-permission" id="user_permission" style={{ width:"350px" }}>
              <div className="grid grid-cols-1">
                <div className="col-span-6 sm:col-span-4">
                  <label
                    for="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    الكيلومتر
                  </label>
                  <input
                    type="number"
                    name="kmCost"
                    value={kmCost}
                    placeholder=" الكيلومتر "
                    autocomplete="kmCost"
                    className="mt-1 py-2 px-3 border block w-full shadow-sm sm:text-sm border-1 border-gray-300 rounded-md"
                    onChange={(e) => setKmCost(e.target.value)}
                  />
                </div>
                <div className="col-span-6 sm:col-span-4">
                  <Button
                    color="primary"
                    disabled={isLoading}
                    size="small"
                    type="button"
                    variant="contained"
                    className="mt-3"
                    onClick={editSetting}
                  >
                    {isLoading ? (
                      <CircularProgress color="secondary" />
                    ) : (
                      "تعديل الاعدادات"
                    )}
                  </Button>
                  {response && (
                    <Alert severity={response.severity}>{response.val} </Alert>
                  )}
                </div>
                </div>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>أغلاق</Button>
        </DialogActions>
      </Dialog>

      {/* Add setting */}
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      المستخدم
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      الكيلومتر
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      التاريخ
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {settings &&
                    settings.map((setting) => {
                      return (
                        <tr key={setting.id} id={setting.id}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-shrink-0 h-10 w-10">
                                {/* Avatar */}
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src={userData.avatar}
                                  alt=""
                                />
                              </div>
                              <div className="mr-4">
                                <div className="text-sm font-medium text-gray-900">
                                  {userData.username}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {setting.km_cost}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                              {setting && new Date(setting.notification_to_send).toLocaleDateString()}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
