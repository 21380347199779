import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Axios from "axios";
export const UpdateUserProfile = ({ open, setOpen, user, setRefresh, refresh }) => {
  const [username, setUsername] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if(user){
      setUsername(user.username);
      setJobTitle(user.job_title);
    }
  },[]);
  const updateUser = async () => {
    if (username && jobTitle) {
      try {
        setIsLoading(true);
        await Axios.patch(`/api/v1/users/information/${user.id}`, {
          username,
          jobTitle,
        });
        setResponse({
          severity: "success",
          val: "تم التعديل بنجاح",
        });
        setIsLoading(false);
        let isRefresh = ! refresh
        setRefresh(isRefresh)
      } catch (err) {
        setResponse({
          severity: "error",
          val: "خطأ, يرجي المحاولة مرة اخري",
        });
      }
      setTimeout(() => {
        setResponse("");
      }, 2000);
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">تعديل بيانات الحساب</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form action="#" method="POST" className="relative">
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        for="username"
                        className="block text-sm font-medium text-gray-700"
                      >
                        الاسم الكامل
                      </label>
                      <input
                        type="text"
                        name="username"
                        value={username}
                        className="mt-1 focus:ring-indigo-500 py-2 px-3 border focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-1 border-gray-300 rounded-md"
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        for="jobTitle"
                        className="block text-sm font-medium text-gray-700"
                      >
                        المسمي الوظيفي
                      </label>
                      <input
                        type="text"
                        name="jobTitle"
                        value={jobTitle}
                        className="mt-1 focus:ring-indigo-500 py-2 px-3 border focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-1 border-gray-300 rounded-md"
                        onChange={(e) => setJobTitle(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <Button
                    color="primary"
                    disabled={isLoading}
                    size="small"
                    type="button"
                    variant="contained"
                    className="mb-3"
                    onClick={() => updateUser()}
                  >
                    {isLoading ? (
                      <CircularProgress color="secondary" />
                    ) : (
                      "تعديل"
                    )}
                  </Button>
                  {response && (
                    <Alert severity={response.severity}>{response.val} </Alert>
                  )}
                </div>
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>أغلاق</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
