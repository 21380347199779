import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Alert,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Dialog,
  Select,
  FormControl,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Axios from "axios";
export const UpdateVisitPopup = ({ open, setOpen, visit, setVisit }) => {
  const [date, setDate] = useState("");
  const [status, setStatus] = useState("");
  const [location, setLocation] = useState("");
  const [kmNumber, setKmNumber] = useState("");
  const [fromCountry, setFromCountry] = useState("");
  const [toCountry, setToCountry] = useState("");
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setVisit(null);
  };
  useEffect(() => {
    setDate(visit.date);
    setStatus(visit.status);
    setLocation(visit.location);
    setKmNumber(visit.kmNumber);
    setFromCountry(visit.fromCountry);
    setToCountry(visit.toCountry);
  },[]);
  const updateVisit = async () => {
    if (visit.user_id) {
      try {
        setIsLoading(true);
        await Axios.patch(`/api/v1/visits/${visit.id}`, {
          date,
          status,
          kmNumber,
          location,
          fromCountry,
          toCountry,
        });
        getVisits()
        setResponse({
          severity: "success",
          val: "تم التعديل بنجاح",
        });
        setIsLoading(false);
      } catch (err) {
        setResponse({
          severity: "error",
          val: "خطأ, يرجي المحاولة مرة اخري",
        });
      }
      setTimeout(() => {
        setResponse("");
      }, 2000);
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">تعديل بيانات المشوار</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form action="#" method="POST" className="relative">
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        for="date"
                        className="block text-sm font-medium text-gray-700"
                      >
                        التاريخ { new Date(date).toLocaleDateString() }
                      </label>
                      <input
                        type="date"
                        name="date"
                        value={date}
                        className="mt-1 focus:ring-indigo-500 py-2 px-3 border  focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        for="fromCountry"
                        className="block text-sm font-medium text-gray-700"
                      >
                        من بلد
                      </label>
                      <select
                        name="fromCountry"
                        value={fromCountry}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        onChange={(e) => setFromCountry(e.target.value)}
                      >
                        {/* Select box country not complete yet */}
                        <option>بلد</option>
                        <option>بلد</option>
                        <option>بلد</option>
                        <option>بلد</option>
                      </select>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        for="toCountry"
                        className="block text-sm font-medium text-gray-700"
                      >
                        الي بلد
                      </label>
                      <select
                        name="toCountry"
                        value={toCountry}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        onChange={(e) => setToCountry(e.target.value)}
                      >
                        {/* Select box country not complete yet */}
                        <option>بلد</option>
                        <option>بلد</option>
                        <option>بلد</option>
                        <option>بلد</option>
                      </select>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        for="location"
                        className="block text-sm font-medium text-gray-700"
                      >
                        الموقع
                      </label>
                      <input
                        type="text"
                        name="location"
                        value={location}
                        className="mt-1 focus:ring-indigo-500 py-2 px-3 border focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-1 border-gray-300 rounded-md"
                        onChange={(e) => setLocation(e.target.value)}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        for="kmNumber"
                        className="block text-sm font-medium text-gray-700"
                      >
                        عدد الكيلومتر
                      </label>
                      <input
                        type="number"
                        name="kmNumber"
                        defaultValue={kmNumber}
                        className="mt-1 focus:ring-indigo-500 py-2 px-3 border focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-1 border-gray-300 rounded-md"
                        onChange={(e) => setKmNumber(e.target.value)}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        for="status"
                        className="block text-sm font-medium text-gray-700"
                      >
                        الحالة
                      </label>
                      <select
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option
                          value="done"
                          // selected={status == "done" ? "selected" : ""}
                        >
                          مكتمل
                        </option>
                        <option
                          value="pending"
                          // // selected={status == "pending" ? "selected" : ""}
                        >
                          أنتظار
                        </option>
                        <option
                          value="canceled"
                          // // selected={status == "canceled" ? "selected" : ""}
                        >
                          ملغي
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <Button
                    color="primary"
                    disabled={isLoading}
                    size="small"
                    type="button"
                    variant="contained"
                    className="mb-3"
                    onClick={() => updateVisit()}
                  >
                    {isLoading ? (
                      <CircularProgress color="secondary" />
                    ) : (
                      "تعديل"
                    )}
                  </Button>
                  {response && (
                    <Alert severity={response.severity}>{response.val} </Alert>
                  )}
                </div>
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>أغلاق</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
