import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../Context/Authentication";
import Axios from "axios";
export const Navbar = () => {
  const { userData, isAuth } = useContext(AuthContext);
  const history = useHistory()
  const logout = async () => {
    try {
      await Axios('/api/v1/logout');
      history.push('/login')
    } catch (err) {}
  };

  return (
    <div className="nav-fixed">
      <nav className="bg-indigo-600">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <img
                  className="h-8 w-8"
                  src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                  alt="Workflow"
                />
              </div>
            </div>
            <div className="block">
              <div className="ml-4 flex items-center md:ml-6">
                <button onClick={() => logout()} className="py-1 px-2 bg-white rounded-md">
                   تسجيل الخروج 
                </button>

                <div className="ml-3 hidden relative">
                  <div
                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu-button"
                    tabindex="-1"
                  >
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700"
                      role="menuitem"
                      tabindex="-1"
                      id="user-menu-item-0"
                    >
                      Your Profile
                    </a>

                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700"
                      role="menuitem"
                      tabindex="-1"
                      id="user-menu-item-1"
                    >
                      Settings
                    </a>

                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700"
                      role="menuitem"
                      tabindex="-1"
                      id="user-menu-item-2"
                    >
                      Sign out
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* <header className="bg-white shadow">
    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
      <h1 className="text-3xl font-bold text-gray-900">
        Dashboard
      </h1>
    </div>
  </header> */}
    </div>
  );
};
