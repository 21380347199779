import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../Context/Authentication";
import {
  Button,
  CircularProgress,
  Alert,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Axios from "axios";
export const UpdateUserPopup = ({ open, setOpen, user, setUser }) => {
  const [username, setUsername] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  //
  const handleClose = () => {
    setOpen(false);
    setUser(null);
  };
  useEffect(() => {
    setUsername(user.username);
    setJobTitle(user.jobTitle);
  });
  const updateUser = () => {
    updateUserInformation();
    updateUserPermissionCheckbox('user')
    updateUserPermissionCheckbox('visit')
    updateUserPermissionCheckbox('setting')
    updateUserPermissionCheckbox('comment')
    updateUserPermissionCheckbox('task')
    updateUserPermissionCheckbox('payment')
  }
  const updateUserInformation = async () => {
    try {
      setIsLoading(true);
      await Axios.patch(
        `/api/v1/users/information/${user.id}`,
        jobTitle,
        username
      );
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const updateUserPermissionCheckbox = (permissionType) => {
    let inputs = document.querySelectorAll(
      `#${permissionType} input[type="checkbox"]`
    );
    let permissionValues = {};
    inputs.forEach((input) => {
      console.log(input.checked);
      permissionValues[input.name] = input.checked ? true : false;
    });
    updateUserPermissionRequest(permissionType, permissionValues);
  };
  const updateUserPermissionRequest = async (
    permissionType,
    permissionValues
  ) => {
    console.log(permissionValues);
    try {
      setIsLoading(true);
      await Axios.patch(
        `/api/v1/users/${user.id}/permission/${permissionType}`,{
        permissionValue: JSON.stringify(permissionValues)
      });
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const displayUserPermissionCheckbox = (perType) => {
    const translationItem = (item) => {
      switch (item) {
        case "add":
          return "أضافة";
          break;
        case "view":
          return "عرض";
          break;
        case "edit":
          return "تعديل";
          break;
        case "delete":
          return "حذف";
          break;
        default:
          return item;
          break;
      }
    };
    return Object.entries(perType).map((per) => {
      return (
        <FormGroup>
          <FormControlLabel
            control={<Checkbox name={per[0]} defaultChecked={per[1]} />}
            label={translationItem(per[0])}
          />
        </FormGroup>
      );
    });
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">تعديل بيانات المستخدم</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="form-update-permission mb-2">
              <div className="mb-2">
                <label
                  for="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  الاسم الكامل
                </label>
                <input
                  type="text"
                  value={username}
                  name="username"
                  placeholder="الاسم الكامل"
                  autocomplete="username"
                  className="mt-1 focus:ring-indigo-500 py-2 px-3 border focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-1 border-gray-300 rounded-md"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="mb-2">
                <label
                  for="jobTitle"
                  className="block text-sm font-medium text-gray-700"
                >
                  المسمي الوظيفي
                </label>
                <input
                  type="text"
                  name="jobTitle"
                  value={job_title}
                  placeholder="المسمي الوظيفي"
                  autocomplete="mobile"
                  className="mt-1 py-2 px-3 border block w-full shadow-sm sm:text-sm border-1 border-gray-300 rounded-md"
                  onChange={(e) => setJobTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6">
            <div className="form-update-permission mb-2" id="user">
              <h4>صلاحيات المستخدم</h4>
              <div className="fields">
                {user &&
                  displayUserPermissionCheckbox(
                    JSON.parse(user["user_permission"])
                  )}
              </div>
            </div>
            <div className="form-update-permission mb-2" id="visit">
              <h4>صلاحيات المشاوير</h4>
              <div className="fields">
                {user &&
                  displayUserPermissionCheckbox(
                    JSON.parse(user["visit_permission"])
                  )}
              </div>
            </div>
            <div className="form-update-permission mb-2" id="task">
              <h4>صلاحيات المهمات</h4>
              <div className="fields">
                {user &&
                  displayUserPermissionCheckbox(
                    JSON.parse(user["task_permission"])
                  )}
              </div>
            </div>
            <div className="form-update-permission mb-2" id="setting">
              <h4>صلاحيات الاعدادات</h4>
              <div className="fields">
                {user &&
                  displayUserPermissionCheckbox(
                    JSON.parse(user["setting_permission"])
                  )}
              </div>
            </div>
            <div className="form-update-permission mb-2" id="payment">
              <h4>صلاحيات المدفوعات</h4>
              <div className="fields">
                {user &&
                  displayUserPermissionCheckbox(
                    JSON.parse(user["payment_permission"])
                  )}
              </div>
            </div>
            <div className="form-update-permission mb-2" id="comment">
              <h4>صلاحيات التعليقات</h4>
              <div className="fields">
                {user &&
                  displayUserPermissionCheckbox(
                    JSON.parse(user["comment_permission"])
                  )}
              </div>
            </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button
            color="primary"
            disabled={isLoading}
            size="sm"
            type="button"
            variant="contained"
            className="mt-1 p-1"
            onClick={() => updateUser()}
          >
            {isLoading ? <CircularProgress color="secondary" /> : "حفظ"}
          </Button>

          <Button onClick={() => handleClose()}>أغلاق</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
