import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { Link, useHistory, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { UpdateUserPopup } from '../Components/User/UpdateUserPopup'
import { AuthContext } from "../Context/Authentication";
import {  Button } from "@mui/material";
import fileDownload from "js-file-download";

export default function Users() {
  const { userData, isAuth  } = useContext(AuthContext);
  const [userPer, setUserPer] = useState([]);
  const [users, setUsers] = useState(null);
  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(false);
  const history = useHistory()
  const userPermissions = () => {
    if (userData) {
      setUserPer(JSON.parse(userData["userPermission"]));
      if(userPer && ! userPer.view) return <Redirect to='/' />
    }
  };
  useEffect(() => {
    const getUsers = async () => {
      try {
        let { data: allUsers } = await Axios("/api/v1/users");
        await setUsers(allUsers.data);
      } catch (error) {
        console.log(error);
      }
    };
    getUsers();
    userPermissions()
  }, []);
  const openPopupEdit = (user) => {
    setUser(user)
    setOpen(true)
  }
  const deleteUser = async (userId) => {
    try {
    document.getElementById(userId).classList +="bg-red-200 ";
    await Axios.delete(`/api/v1/users/${userId}`);
    userId && document.getElementById(userId).remove();
    } catch(err){

    }
  }
  const handelDownload = (url, filename) => {
    Axios.get(url, {
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, filename);
    });
  };
  const displayTaskFiles = (attach) => {
    if (attach) {
      let attachList = JSON.parse(attach);
      return attachList.map((attachFile, index) => {
        return (
          <Button
            size="small"
            className="ml-1 mb-1 "
            color="success"
            type="button"
            variant="contained"
            onClick={() => handelDownload(attachFile, `مرفق ${index}`)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
            حمل المرفق
          </Button>
        );
      });
    }
  };
  return (
    <>
      <Helmet>
        <title>المستخدمين</title>
      </Helmet>
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-3xl font-bold text-gray-900">المستخدمين</h1>
          {( userPer && userPer.add ) && (
          <Link
            to="/add-user"
            className="text-white  mr-1 text-sm bg-green-500 rounded-md p-2 hover:bg-green-300 flex align-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4v16m8-8H4"
              />
            </svg>
            <span>أضافة مستخدم</span>
          </Link>
)}

        </div>
      </header>
     {/* Update user popup */}
     { user && <UpdateUserPopup user={user} setUser={setUser} setOpen={setOpen} open={open} />}
     {/* Update user popup */}
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      الاسم
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      الوظيفية
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      البلد
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      الصلاحيات
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      المرفقات
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only"></span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {users &&
                  users.map((user) => {
                    return (
                        <tr  key={user.id} id={user.id}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-shrink-0 h-10 w-10">
                                {/* Aavatar */}
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"
                                  alt=""
                                />
                              </div>
                              <div className="mr-4">
                                <div className="text-sm font-medium text-gray-900">
                                  {user.username ? user.username : 'بدون اسم' }
                                </div>
                                <div className="text-sm text-gray-500">
                                  { user.mobile  }
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {user.job_title ? user.job_title : 'لا يوجد مسمي وظيفي' }
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {user.country ? user.country : 'لا يوجد بلد' }
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                              الصلاحيات
                            </span>
                          </td>
                          <td className="flex flex-column px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {user.attach ? displayTaskFiles(user.attach) : 'لا يوجد مرفقات' }
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          {( userPer && userPer.edit ) && (
                            <a
                              href="#"
                              onClick={e => openPopupEdit(user)}
                              className="text-indigo-600 hover:text-indigo-900 ml-1"
                            >
                              تعديل
                            </a>
                            )} 
                            {( userPer && userPer.delete ) && (
                            <a
                              href="#"
                              className="text-red-600 hover:text-red-900 mr-1"
                              onClick={() => deleteUser(user.id)}
                            >
                              حذف
                            </a>
                            )}
                          </td>
                        </tr>
                    );
                  })}
                  </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
