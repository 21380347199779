import React, { useContext } from 'react';
import { useHistory, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { AuthContext } from '../../Context/Authentication';

function PrivateRoute({ children, ...rest }) {
  const history = useHistory()
  const { isAuth, authLoading } = useContext(AuthContext);
  if (isAuth && !authLoading) {
    return (
      <Route {...rest}>
        {children}
      </Route>
    );
  }
  return <Redirect to={{pathname: '/login'}} />

}
PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
