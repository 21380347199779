import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { AuthContext } from "../../Context/Authentication";
import "../../Styles/tasks.css";
import { UpdateComment } from "./UpdateComment";

export const UserComment = ({ comment, getComments, commentPer }) => {
  const [isUpdate, setIsUpdate] = useState(false);
  const { userData, isAuth } = useContext(AuthContext);
  const [user, setUser] = useState({});
  const getUserData = async () => {
    try {
      await Axios(`/api/v1/users/${comment.user_id}`).then((res) => {
        console.log(res.data);
        setUser(res.data.data);
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getUserData();
  }, []);
  const editComment = (commentId) => {
    document.getElementById(`boxComment${commentId}`).classList.add("editing");
  };
  const deleteComment = async (commentId) => {
    try {
      await Axios.delete(`/api/v1/task/comments/${commentId}`).then((res) => {
        document.getElementById(`boxComment${commentId}`).classList +=
          "bg-red-300";
        document.getElementById(`boxComment${commentId}`).remove();
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="task-comment-box" id={`boxComment` + comment.id}>
      <UpdateComment commentContent={comment} getComments={getComments} />
      <div className="flex">
        <img src={user && user.avatar} alt={user && user.username} />
        <div className="task-comment-box-content">
          {user.id === comment.user_id && (
            <div className="comment-control">
              {commentPer && commentPer.edit && (
                <button
                  onClick={() => editComment(comment.id)}
                  className="rounded-sm bg-green-500 text-white"
                >
                  {" "}
                  تعديل{" "}
                </button>
              )}
              {commentPer && commentPer.delete && (
                <button
                  onClick={() => deleteComment(comment.id)}
                  className="rounded-sm bg-red-500 text-white"
                >
                  {" "}
                  حذف{" "}
                </button>
              )}
            </div>
          )}
          <h4>{user && user.username}</h4>
          <p>{comment.comment}</p>
        </div>
      </div>
    </div>
  );
};
