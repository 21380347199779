import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../Context/Authentication";
import { getTaskById } from "../../Context/Queries";
import {
  Button,
  CircularProgress,
  Alert,
  FormControlLabel,
  FormGroup,
  Checkbox,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
} from "@mui/material";
import Axios from "axios";
export const UpdateTaskStep1 = (props) => {
  const [taskId, setTaskId] = useState("");
  const [date, setDate] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [deadline, setDeadline] = useState("");
  const [visibility, setVisibility] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const getDate = async () => {
    let { task } = await props;
    setTaskId(task.id);
    setDate(task.date);
    setName(task.name);
    setType(task.type);
    setDeadline(task.deadline);
    setVisibility(task.visibility);
  }
  useEffect(() => {
    getDate()
  }, []);

  const updateTaskInfo = async () => {
    console.log(taskId)
    try {
      setIsLoading(true);
      await Axios.patch(`/api/v1/tasks/${taskId}`, {
        name,
        type,
        date,
        deadline,
        visibility,
      });
      setIsLoading(false);
      setResponse({
        severity: "success",
        val: "تم تعديل التاسك بنجاح",
      });
    } catch (err) {
      console.log(err)
      setResponse({
        severity: "error",
        val: "خطأ, يرجي المحاولة مرة اخري",
      });
    }
  };

  // const displayUserPermissionCheckbox = (perType) => {
  //   const translationItem = (item) => {
  //     switch (item) {
  //       case "add":
  //         return "أضافة";
  //         break;
  //       case "view":
  //         return "عرض";
  //         break;
  //       case "edit":
  //         return "تعديل";
  //         break;
  //       case "delete":
  //         return "حذف";
  //         break;
  //       default:
  //         return item;
  //         break;
  //     }
  //   };
  //   return Object.entries(perType).map((per) => {
  //     return (
  //       <FormGroup>
  //         <FormControlLabel
  //           control={<Checkbox name={per[0]} defaultChecked={per[1]} />}
  //           label={translationItem(per[0])}
  //         />
  //       </FormGroup>
  //     );
  //   });
  // };
  return (
    <>
      <form className="form-update-permission grid grid-cols-1 sm:grid-cols-6">
        <div className="sm:col-span-4 mb-3">
          <label for="name" className="block text-sm font-medium text-gray-700">
            الاسم
          </label>
          <input
            type="text"
            value={name}
            name="name"
            placeholder="الاسم الكامل"
            autocomplete="name"
            className="mt-1 focus:ring-indigo-500 py-2 px-3 border focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-1 border-gray-300 rounded-md"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="sm:col-span-4 mb-3">
          <label for="date" className="block text-sm font-medium text-gray-700">
            التاريخ{" "}
            <span className="date-format">
              {new Date(date).toLocaleDateString()}
            </span>
          </label>
          <input
            type="date"
            name="date"
            autocomplete="name"
            className="mt-1 focus:ring-indigo-500 py-2 px-3 border focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-1 border-gray-300 rounded-md"
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="sm:col-span-4 mb-3">
          <label
            for="deadline"
            className="block text-sm font-medium text-gray-700"
          >
            موعد الانتهاء{" "}
            <span className="date-format">
              {new Date(deadline).toLocaleDateString()}
            </span>
          </label>
          <input
            type="date"
            name="deadline"
            autocomplete="name"
            className="mt-1 focus:ring-indigo-500 py-2 px-3 border focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-1 border-gray-300 rounded-md"
            onChange={(e) => setDeadline(e.target.value)}
          />
        </div>
        <div className="sm:col-span-4 mb-3">
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <MenuItem
                value="settion"
                selected={type == "settion" ? "selected" : ""}
              >
                تسوية
              </MenuItem>
              <MenuItem
                value="other"
                selected={type == "other" ? "selected" : ""}
              >
                أخري
              </MenuItem>
              <MenuItem
                value="normal"
                selected={type == "normal" ? "selected" : ""}
              >
                عادي
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="sm:col-span-4 mb-3">
          <FormControlLabel
            className="m-0"
            control={
              <Checkbox
                name="visibility"
                onChange={(e) => setVisibility(e.target.checked)}
                checked={visibility}
              />
            }
            label="الروية"
          />
        </div>
        <div className="sm:col-span-4 mb-3">
          <Button
            color="primary"
            disabled={isLoading}
            size="sm"
            type="button"
            variant="contained"
            className="mt-1 p-1"
            onClick={() => updateTaskInfo()}
          >
            {isLoading ? <CircularProgress color="secondary" /> : "حفظ"}
          </Button>
         {response &&  <Alert severity={response.severity}>{response.val} </Alert>}
        </div>
      </form>
    </>
  );
};
