import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../Context/Authentication";
import { getTaskById } from "../../Context/Queries";
import { Button, CircularProgress, Alert } from "@mui/material";
import Axios from "axios";
export const UpdateTaskStep4 = ({ task }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [users, setUsers] = useState(null);
  const [allUsers, setAllUsers] = useState(null);
  const getUser = async () => {
    await Axios(`/api/v1/task/${task.id}/users`).then((res) => {
      setUsers(res.data.data);
      console.log(res.data.data);
    });
  };
  const getUsers = async () => {
    try {
      let { data: allUsers } = await Axios("/api/v1/users");
      await setAllUsers(allUsers.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUsers();
    getUser();
  }, []);
  const deleteTaskUser = async (userId) => {
    try {
      await Axios.delete(`/api/v1/user/${userId}/tasks/${task.id}`).then(
        (res) => {
          getUsers();
          getUser();
        }
      );
    } catch (err) {}
  };
  const updateTaskUserStatus = async (status, userId) => {
    try {
      await Axios.patch(`/api/v1/user/${userId}/tasks/${task.id}`, {
        status,
      }).then((res) => {
        getUser();
      });
    } catch (err) {
      console.log(err);
    }
  };
  const addUserTask = async (e, userId) => {
    try {
      setIsLoading(true);
      await Axios.post(`/api/v1/user/${userId}/tasks/${task.id}`).then(
        (res) => {
          e.target.innerText = "مضاف";
          e.target.setAttribute("disabled", "disabled");
          setTimeout(() => {
            getUsers();
            getUser();
          }, 2000);
        }
      );
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const checkIfExist = (userId, user) => {
    let check = users ?
      users.filter((userTask) => {
        console.log(userTask.user_id);
        return userTask.user_id === userId;
      }) : [];
    console.log(check);
    if (! check.length) {
      return (
        <tr key={`userId` + user.user_id} id={`userTable` + user.user_id}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex items-center">
              <div className="flex-shrink-0 h-10 w-10">
                {/* Avatar */}
                <img
                  className="h-10 w-10 rounded-full"
                  src={user.avatar}
                  alt=""
                />
              </div>
              <div className="mr-4">
                <div className="text-sm font-medium text-gray-900">
                  {user.username ? user.username : "بدون اسم"}
                </div>
                <div className="text-sm text-gray-500">{user.mobile}</div>
              </div>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">
              {user.job_title ? user.job_title : "لا يوجد مسمي وظيفي"}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
            <Button
              disabled={isLoading}
              size="small"
              variant="contained"
              type="button"
              onClick={(e) => addUserTask(e, user.id)}
            >
              أضافة
            </Button>
          </td>
        </tr>
      );
    } else {
      console.log("run err");
    }
  };
  return (
    <>
      {users && (
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  الاسم
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  الوظيفية
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  الحالة
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only"></span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {users.map((user) => {
                return (
                  <tr key={user.user_id} id={`user-` + user.user_id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          {/* Avatar */}
                          <img
                            className="h-10 w-10 rounded-full"
                            src={user.avatar}
                            alt=""
                          />
                        </div>
                        <div className="mr-4">
                          <div className="text-sm font-medium text-gray-900">
                            {user.username ? user.username : "بدون اسم"}
                          </div>
                          <div className="text-sm text-gray-500">
                            {user.mobile}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {user.job_title ? user.job_title : "لا يوجد مسمي وظيفي"}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        <select
                          className="mt-1 focus:ring-indigo-500 py-2 px-3 border  focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={(e) =>
                            updateTaskUserStatus(e.target.value, user.user_id)
                          }
                          defaultValue={user.status}
                        >
                          <option value="done">أكتمل</option>
                          <option value="pending">أنتظار</option>
                          <option value="onwork">في العمل</option>
                        </select>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        type="button"
                        onClick={(e) => deleteTaskUser(user.user_id)}
                      >
                        أزالة
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <h2 className="my-2 mt-5">أضافة أعضاء جدد </h2>
      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                الاسم
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                الوظيفية
              </th>
              <th scope="col" className="relative px-6 py-3">
                <span className="sr-only"></span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {allUsers && allUsers.map((user) => checkIfExist(user.id, user))}
          </tbody>
        </table>
      </div>
    </>
  );
};
