import { Switch, Route, useHistory } from "react-router-dom";
import React, { useLayoutEffect, Suspense, useContext } from "react";
import Dashboard from "../../pages/Dashboard";
import Profile from "../../pages/Profile";
import NotFound from "../../pages/NotFound";
import Setting from "../../pages/Setting";
import Tasks from "../../pages/Tasks";
import Task from "../../pages/Task";
import Users from "../../pages/Users";
import { AddUserTask } from "../../Components/User/AddUserTask";
import { AddTask } from "../../Components/Tasks/AddTask";
import { UpdateTask } from "../../Components/Tasks/UpdateTask";
import Visits from "../../pages/Visits";
import { AddVisit } from "../../Components/Visits/AddVisit";
import Payments from "../../pages/payments";
import { AddPayment } from "../../Components/Payments/AddPayment";
import Login from "../../pages/Login";
import AddUser from "../../pages/AddUser";
import { AuthContext } from "../../Context/Authentication";
import PrivateRoute from "./PrivateRoutes";
import LoggedOutRoutes from "./LoggedOutRoutes";

const AppRoutes = () => {
  const { isAuth } = useContext(AuthContext);
  return (
    <>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route path="/users" component={Users} />
        <Route path="/visits" component={Visits} />
        <Route path="/add-visit" component={AddVisit} />
        <Route exact path="/tasks" component={Tasks} />
        <Route path="/add-task" component={AddTask} />
        <Route path="/tasks/:id" component={UpdateTask} />
        <Route path="/task/:id" component={Task} />
        <Route path="/settings" component={Setting} />
        <Route path="/payments" component={Payments} />
        <Route path="/add-payment" component={AddPayment} />
        {/* <Route path="*" exact={true} component={NotFound} /> */}
        <Route path="/add-user" component={AddUser} />
        <Route path="/profile" component={Profile} />
        <Route path="/login" component={Login} />
      </Switch>
    </>
  );
};

export default AppRoutes;
