import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Axios from "axios";
export const UsersInTask = ({ taskId }) => {
  const [users, setUsers] = useState([]);
  const getUserInTask = async (taskId) => {
    console.log(taskId);

    if (taskId) {
      try {
        await Axios(`/api/v1/task/${taskId}/users`).then((res) => {
          setUsers(res.data.data);
        });
      } catch (err) {
        console.log(err);
      }
    }
  };
  useEffect(() => {
    getUserInTask(taskId);
    console.log(users);
  }, [taskId]);
  let counter = 0
  return (
    <>
      <div className="flex-shrink-0 h-10 w-10 flex justify-center">
        {(users  && counter < 3) && users.map((user) => {
          counter += 1 
            return (
              <>
                <img
                  className={"h-4 w-4 rounded-full " + (users.length == 1 &&  'h-10 w-10 ') + (counter == 2 && 'mt-3') }
                  src={user.avatar}
                />
              </>
            );
          })}
          <span className="user-task-number">{users.length}</span>
      </div>
      <div className="mr-4">
        <div className="text-sm font-medium text-gray-900">أعضاء الفريق </div>
        <Link to={`/task/${taskId}`} className="text-sm text-indigo-500">
          شاهد المهمة
        </Link>
      </div>
    </>
  );
};
