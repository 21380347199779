import Axios from "axios";
import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
export const AddVisit = () => {
  const [date , setDate] = useState("");
  const [location , setLocation] = useState("");
  const [kmNumber , setKmNumber] = useState("");
  const [fromCountry , setFromCountry] = useState("");
  const [toCountry , setToCountry] = useState("");
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const addNewVisit = async () => {
    if (kmNumber && location && toCountry && fromCountry) {
      try {
        setIsLoading(true);
        await Axios.post(
          "/api/v1/visits/",
          {
            date,kmNumber,location,fromCountry,toCountry,
          });
        setResponse({
          severity: "success",
          val: "تم اضافة مشوار بنجاح",
        });
        setIsLoading(false);
      } catch (err) {
        setResponse({
          severity: "error",
          val: "خطأ, يرجي المحاولة مرة اخري",
        });
      }
      setTimeout(() => {
        setResponse('')
      },2000)
    }
  };
  return (
    <>
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-3xl font-bold text-gray-900">أضافة مشوار</h1>
        </div>
      </header>
      <form action="#" method="POST" className="relative">
        <div className="shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label
                  for="date"
                  className="block text-sm font-medium text-gray-700"
                >
                  التاريخ
                </label>
                <input
                  type="date"
                  name="date"
                  id="date"
                  autocomplete="date"
                  className="mt-1 focus:ring-indigo-500 py-2 px-3 border  focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={e => setDate(e.target.value)}
                  
            />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  for="FromCountry"
                  className="block text-sm font-medium text-gray-700"
                >
                  من بلد
                </label>
                <select
                  id="FromCountry"
                  name="FromCountry"
                  autocomplete="FromCountry"
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  onChange={e => setFromCountry(e.target.value)}
              
              >
                  {/* Select box country not complete yet */}
                  <option>بلد</option>
                  <option>بلد</option>
                  <option>بلد</option>
                  <option>بلد</option>
                </select>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  for="toCountry"
                  className="block text-sm font-medium text-gray-700"
                >
                  الي بلد
                </label>
                <select
                  id="toCountry"
                  name="toCountry"
                  autocomplete="toCountry"
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  onChange={e => setToCountry(e.target.value)}
                >
                  {/* Select box country not complete yet */}
                  <option>بلد</option>
                  <option>بلد</option>
                  <option>بلد</option>
                  <option>بلد</option>
                </select>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  for="location"
                  className="block text-sm font-medium text-gray-700"
                >
                  الموقع
                </label>
                <input
                  type="text"
                  name="location"
                  id="location"
                  placeholder="الموقع"
                  autocomplete="location"
                  className="mt-1 focus:ring-indigo-500 py-2 px-3 border focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-1 border-gray-300 rounded-md"
                  onChange={e => setLocation(e.target.value)}
               
               />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  for="kmNumber"
                  className="block text-sm font-medium text-gray-700"
                >
                  عدد الكيلومتر
                </label>
                <input
                  type="number"
                  name="kmNumber"
                  id="kmNumber"
                  autocomplete="kmNumber"
                  className="mt-1 focus:ring-indigo-500 py-2 px-3 border focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-1 border-gray-300 rounded-md"
                  onChange={e => setKmNumber(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <Button
              color="primary"
              disabled={isLoading}
              size="small"
              type="button"
              variant="contained"
              className="mb-3"
              onClick={addNewVisit}
            >
              {isLoading ? <CircularProgress color="secondary" /> : "أضافة"}
            </Button>
            {response && <Alert severity={response.severity}>{response.val} </Alert>}
          </div>
        </div>
      </form>
    </>
  );
};
