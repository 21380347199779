import Axios from "axios";
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { AddTaskStep1 } from "./AddTaskStep1";
import { AddTaskStep2 } from "./AddTaskStep2";
import { AddTaskStep3 } from "./AddTaskStep3";
import { AddTaskStep4 } from "./AddTaskStep4";
import { Button, CircularProgress, Alert } from "@mui/material";
import "../../Styles/tasks.css";
export const AddTask = () => {
  const [taskId, setTaskId] = useState(null);
  const [step, setStep] = useState(1);
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const nextStep = () => {
    setStep(step + 1);
  };
  const prevStep = () => {
    setStep(step - 1);
  };
  const formSteps = () => {
    // let step = step
    switch (step) {
      case 1:
        return <AddTaskStep1 setTaskId={setTaskId} />;
      case 2:
        return <AddTaskStep2 taskId={taskId} />;
      case 3:
        return <AddTaskStep3 taskId={taskId} />;
      case 4:
        return <AddTaskStep4 taskId={taskId} />;
      case 5:
        return "Congratolations";
    }
  };
  return (
    <>
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-3xl font-bold text-gray-900">أضافة مهمة</h1>
        </div>
      </header>
      <div className="" style={{ maxWidth: "600px" }}>
        <div className="steps-dot flex align-center">
          <span className="dot active">1</span>
          <span className={"line " + (step > 1 ? " active" : "")}></span>
          <span className={"dot " + (step > 1 ? " active" : "")}>2</span>
          <span className={"line " + (step > 2 ? " active" : "")}></span>
          <span className={"dot " + (step > 2 ? " active" : "")}>3</span>
          <span className={"line " + (step > 3 ? " active" : "")}></span>
          <span className={"dot " + (step > 3 ? " active" : "")}>4</span>
          {/* <span className={"line " + (step > 4 ? " active" : "")}></span>
          <span className={"dot " + (step > 4 ? " active" : "")}>5</span> */}
        </div>
        {formSteps()}
        <div className="steps-control mt-3 mb-5">
        {(step > 1) && <Button color="secondary" className="ml-3" onClick={ () => prevStep() }>السابق</Button>}
        {(step < 4  && taskId) && <Button   color="secondary" onClick={ () => nextStep() }>التالي</Button>}
        {(step == 4  && taskId) && <Button variant="contained" ><Link to="/tasks">حفظ</Link> </Button>}
        </div>
      </div>
    </>
  );
};
