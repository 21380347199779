import Axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, CircularProgress, Alert } from "@mui/material";

export const AddPayment = () => {
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState(null);
  const [userId, setUserId] = useState(null);
  const [amount, setAmount] = useState(null);
  useEffect(() => {
    const getUsers = async () => {
      try {
        await Axios("/api/v1/users").then((res) => {
          setUsers(res.data.data);
          console.log(users);
        });
      } catch (error) {
        console.log(error);
      }
    };
    getUsers();
  }, []);
  const displayUsers = () => {
    return (
      users &&
      users.map((user) => {
        return (
          <option
            key={user.id}
            value={user.id}
            className="select-user-box flex"
          >
            {user.username}
          </option>
        );
      })
    );
  };
  const addPayment = async () => {
    if (amount && userId) {
      try {
        setIsLoading(true);
        await Axios.post(`/api/v1/payments/${userId}`, {
          amount,
        });
        setResponse({
          severity: "success",
          val: "تم اضافة دفعة بنجاح",
        });
        setAmount(null)
        setIsLoading(false);
      } catch (err) {
        setResponse({
          severity: "error",
          val: "خطأ, يرجي المحاولة مرة اخري",
        });
      }
      setTimeout(() => {
        setResponse("");
      }, 2000);
    }
  };
  return (
    <>
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-3xl font-bold text-gray-900">أضافة دفع جديد</h1>
        </div>
      </header>
      <form action="#" method="POST" className="relative">
        <div className="shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-4">
                <label
                  for="userId"
                  className="block text-sm font-medium text-gray-700"
                >
                  المستخدم
                </label>
                <select
                  id="userId"
                  name="userId"
                  autocomplete="userId"
                  onChange={(e) => setUserId(e.target.value)}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  {displayUsers()}
                </select>
              </div>

              <div className="col-span-6 sm:col-span-4">
                <label
                  for="amount"
                  className="block text-sm font-medium text-gray-700"
                >
                  المبلغ
                </label>
                <input
                  type="number"
                  name="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="المبلغ"
                  autocomplete="amount"
                  className="mt-1 focus:ring-indigo-500 py-2 px-3 border focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-1 border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <Button
              color="primary"
              disabled={isLoading}
              size="small"
              type="button"
              variant="contained"
              className="mb-3"
              onClick={addPayment}
            >
              {isLoading ? <CircularProgress color="secondary" /> : "حفظ"}
            </Button>
            {response && (
              <Alert severity={response.severity}>{response.val} </Alert>
            )}
          </div>
        </div>
      </form>
    </>
  );
};
