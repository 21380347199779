import React, { useState, useEffect } from 'react'
import Axios from 'axios';
export const Members = () => {
  const [users, setUsers] = useState(null);
  const limit = (list,limit) => {
    if(list.length > limit) {
      let listLimit = []
      for(let i = 0; i < limit ; i++){
        listLimit.push(list[i])
      }
      return listLimit
    } else return list

  }
  const getUsers = async () => {
    try {
      let { data: allUsers } = await Axios("/api/v1/users");
      await setUsers(limit(allUsers.data,10));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers()
  },[])
  return (
    <>
      { users && users.map(user => {
        return (
          <span>
            <img src={user.avatar} />
          </span>
        )
      })}
    </>
  )
}
